var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "routes-panel" },
    _vm._l(_vm.routeList, function (item, index) {
      return _c(
        "span",
        {
          key: index,
          on: {
            click: function ($event) {
              return _vm.goBack(index - _vm.routeList.length + 1)
            },
          },
        },
        [_vm._v(" " + _vm._s(item.meta.title || "-") + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }